import React, { useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import GeneralLayout from '../components/GeneralLayout';
import '../scss/main.scss';
import CategoryChip from '../components/Works/CategoryChip';
import ThemeChip from '../components/Works/ThemeChip';
import CategoryMenu from '../components/CategoryMenu';

export default function Project({ pageContext }) {
  const {
    category,
    description,
    id,
    mainImage,
    otherImages,
    title,
    theme,
    year
  } = pageContext;

  const listQuery = useStaticQuery(graphql`
    query {
      allSanityProject(filter: { slug: { current: { ne: "null" } } }) {
        nodes {
          slug {
            current
          }
          title
          year
          theme {
            id
            title
            slug {
              current
            }
          }
          category {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          otherImages {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          id
        }
      }
      allSanityTheme(
        sort: {order: ASC, fields: title}
        filter: { slug: { current: { ne: "null" } } }
      ) {
        nodes {
          title
          id
          slug {
            current
          }
          description {
            children {
              text
            }
          }
        }
      }
    }
  `);

  function styleText(paragraph) {
    return (
      <p className={paragraph.children[0].marks.length > 0 ? 'project-text-first strong' : 'project-text-first'}>{paragraph.children[0].text}</p>
    );
  }

  const themesSorted = theme.sort((a, b) => {
    if (a.title > b.title) return 1;
    if (a.title < b.title) return -1;
    return 0;
  });
  const themesList = listQuery.allSanityTheme.nodes.map((node) => ({
    id: node.id,
    title: node.title,
    slug: node.slug
  }));
  const activeCategory = null;

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    document.getElementById('___gatsby').style.overflowY = 'auto';
    document.getElementById('gatsby-focus-wrapper').style.overflowY = 'auto';
  }, []);

  return (
    <GeneralLayout>
      <div className="info">
        <div className="result">
          <div className="result-content project-page">
            <div className="result-header">
              <p className="works">{year[0].substring(0, 4)}</p>
              <h3 className="works">{title}</h3>
            </div>
            <div className="result-chips">
              {themesSorted.map((themeEl) => (
                <Link to={`/works/${themeEl.slug.current}`}>
                  <ThemeChip
                    key={themeEl.id}
                    id={themeEl.id}
                    slug={themeEl.slug.current}
                    themesList={themesList}
                    place="result"
                  />
                </Link>
              ))}
              {category.map((categoryEl, i) => (
                <CategoryChip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${id}-category-chip-${i}`}
                  category={categoryEl.title}
                  place="result"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="project-content">
          <div className="images">
            <GatsbyImage className="project-image project-page" image={mainImage} alt={title} />
            {otherImages ? otherImages.map((otherImage) => (
              <GatsbyImage className="project-image project-page" image={otherImage} alt={title} />
            )) : null}
          </div>
          <div className="text">
            {description ? description.map((paragraph) => styleText(paragraph)) : null}
          </div>
        </div>
      </div>
      <CategoryMenu
        categoryType="Works"
        activeCategory={activeCategory}
        listQuery={listQuery}
      />
    </GeneralLayout>
  );
}
