import React from 'react';

export default function CategoryChip(props) {
  const { category, place } = props;

  function getCategoryChipSvg() {
    let chipSvg;
    const classNameString = place === 'result' ? 'category-chip large' : 'category-chip small';
    switch (category) {
      case 'Urban Design':
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#dedede" />
              <text id="UD" transform="translate(37 53)" fill="#fff"><tspan x="-28.04" y="0">UD</tspan></text>
            </g>
          </svg>
        );
        break;
      case 'Selected Works':
        chipSvg = null;
        break;
      case 'Interior Design':
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#dedede" />
              <text id="ID" transform="translate(37 53)" fill="#fff"><tspan x="-19.68" y="0">ID</tspan></text>
            </g>
          </svg>
        );
        break;
      case 'Progressive Research':
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#dedede" />
              <text id="PR" transform="translate(37 53)" fill="#fff"><tspan x="-25.78" y="0">PR</tspan></text>
            </g>
          </svg>
        );
        break;
      case 'Architecture':
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#dedede" />
              <text id="A" transform="translate(37 53)" fill="#fff"><tspan x="-12.82" y="0">A</tspan></text>
            </g>
          </svg>
        );
        break;
      case 'Publication':
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#dedede" />
              <text id="P" transform="translate(37 53)" fill="#fff"><tspan x="-13.34" y="0">P</tspan></text>
            </g>
          </svg>
        );
        break;
      case 'Product Design':
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#dedede" />
              <text id="PD" transform="translate(37 53)" fill="#fff"><tspan x="-26.42" y="0">PD</tspan></text>
            </g>
          </svg>
        );
        break;
      case 'Exhibition':
        chipSvg = (
          <svg className={classNameString} xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
            <g>
              <rect width="74" height="74" rx="37" fill="#dedede" />
              <text id="E" transform="translate(37 53)" fill="#fff"><tspan x="-12.3" y="0">E</tspan></text>
            </g>
          </svg>
        );
        break;
      default:
        throw new Error('unexpected category value in getCategoryChipSvg');
    }
    return chipSvg;
  }

  return (
    getCategoryChipSvg()
  );
}
